import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { toastr } from 'react-redux-toastr'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import apiClient from '../../gears/apiClient'
import ComponentLoader from '../ComponentLoader'
import { renderExtensionConfigurationFormItems } from '../forms/formsHelper'
import { capitalizeFirstLetter } from '../../helpers/page_helper'
import { issueDetailsArr, onSaveButtonClick } from './issueSettingsDeateilHelper'
import styles from './issueSettingsDetail.module.scss';

const IssueSettingsDetail = ({id, isModalOpen, toggleModal}) => {
  const [issueData, setIssueData] = useState({})
  const [isLoading, setIsLoading] = useState(false);

  const getOrUpdateIssueDetails = async (reason = null, data = {}) => {
    setIsLoading(true);
    const request = reason
      ? apiClient.put(`/disruption-config/${id}`, data)
      : apiClient.get(`/disruption-config/${id}`)

    try {
      const response = await request;

      if(reason) {
        toastr.success('Updated Successfuly');
        toggleModal();
      } else {
        setIssueData(response);
      }
    } catch(e) {
      toastr.error(e)
    }
    setIsLoading(false);
  }

  useEffect(() => {
    getOrUpdateIssueDetails();
  }, [])

  const onCheckboxClick = (e) => {
    const { name } = e.target;
    const copyData = {...issueData};
    copyData[name] = !copyData[name]
    setIssueData(copyData);
  }

  return (
    <Modal
      isOpen={isModalOpen}
      toggle={toggleModal}
      className={`custom ${styles.issueSettingsDetail}`}
    >
      <ModalHeader toggle={toggleModal}>Edit Issue: {issueData?.title}</ModalHeader>
      {isLoading && <ComponentLoader />}
      <ModalBody>
        <div className={styles.description}>{issueData.description}</div>
        <div className={styles.formGroupNoInput}>
          Severity
          <span>{capitalizeFirstLetter(issueData.severity)}</span>
        </div>
        <div className="settings">
          {renderExtensionConfigurationFormItems(
            issueDetailsArr(issueData, onCheckboxClick)
          )}
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          className="button button-default"
          onClick={() => onSaveButtonClick(issueData, getOrUpdateIssueDetails)}
          disabled={isLoading}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  )
}

IssueSettingsDetail.propTypes = {
  id: PropTypes.number,
  isModalOpen: PropTypes.bool,
  toggleModal: PropTypes.func,
}

export default IssueSettingsDetail